<template>
    <div class="header_container">
        <div class="header_left animated fadeInDown" @click="otherClickMenu(1)">
            <img class="logoImg animated fadeInDown" :src="logoPath" :alt="logoAlt" />
        </div>

        <div class="header_center">
            <ul>
                <li class="animated fadeInDown sys_li_1" @click="otherClickMenu(3)">
                    <a href="javascript:;" class="sys_title" @click="otherClickMenu(3)">关于我们</a>
                </li>

                <li class="animated fadeInDown sys_li_1">
                    <a href="javascript:;" class="sys_title">客户案例</a>
                    <ul class="sys_ul sys_ul_serviceSupport">
                        <li class="sys_ul_li">
                            <dl>
                                <dd v-for="item in serviceSList" :key="item.id"
                                    @click="clickMenu(item)"
                                    style="text-align: left; width:250px" class="sys_ul_li_dd">
                                    <a href="javascript:;"
                                       style="font-size:16PX;line-height:10PX;margin-left:25PX;"><i
                                        class="item.icon"></i>{{
                                            item.name
                                        }}</a>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </li>

                <li class="animated fadeInDown sys_li_1">
                    <a href="javascript:;" class="sys_title">硬件选型</a>
                    <ul class="sys_ul sys_ul_serviceSupport">
                        <li class="sys_ul_li">
                            <dl>
                                <dd v-for="item in hardwareList" :key="item.id"
                                    @click="clickMenu(item)"
                                    style="text-align: left; width:250px" class="sys_ul_li_dd">
                                    <a href="javascript:;"
                                       style="font-size:16PX;line-height:10PX;margin-left:25PX;"><i
                                        class="item.icon"></i>{{
                                            item.name
                                        }}</a>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </li>


                <li class="animated fadeInDown sys_li_1">
                    <!-- <a href="#" class="sys_title">解决方案</a> -->
                    <ul class="sys_ul sys_ul_solution">
                        <li class="sys_ul_li2" v-for="item in suloteList"
                            :key="item.id">
                            <a @click="clickMenu(item)" href="javascript:;"
                               class="bg1">
                                <img :src="item.img" :alt="item.name"/>
                                <h3>{{ item.name }}</h3>
                                <p class="lookMore">查看更多</p>
                            </a>
                        </li>
                    </ul>
                </li>
                <li class="animated fadeInDown sys_li_1">
                    <a href="#" class="sys_title">产品介绍</a>
                    <ul class="sys_ul sys_ul_pro" style="width:700px">
                        <li class="sys_ul_li sys_pro_li">
                            <dl>
                                <dt>平台</dt>
                                <dd v-for="item in cloudList" :key="item.id" @click="clickMenu(item)">
                                    <a href="javascript:;" class=""><i class="item.icon"></i>{{ item.name }}</a>
                                </dd>
                            </dl>
                        </li>

                        <li class="sys_ul_li">
                            <dl>
                                <dt>应用</dt>
                                <dd v-for="item in sideList" :key="item.id" @click="clickMenu(item)">
                                    <a href="javascript:;" class=""><i class="item.icon"></i>{{item.name }}</a>
                                </dd>
                            </dl>
                        </li>
                    </ul>
                </li>

                <li class="animated fadeInDown sys_li_1" @click="otherClickMenu(1)">
                    <a href="javascript:;" class="sys_title" @click="otherClickMenu(1)">首页</a>
                </li>
            </ul>
        </div>

        <div class="header_right">
            <ul>
                <li class="animated fadeInDown sys_li_1">
                    <a href="https://cloud.microknown.com/" class="sys_title" target="_blank">登录</a>
                </li>
                <!-- <li class="login animated fadeInDown" v-if="isLogin == 0 || isLogin == undefined">
                  <a href="javascript:;" @click="otherClickMenu(2)">博客系统</a>
                </li> -->
            </ul>
        </div>
    </div>
</template>

<script>

export default {
    inject: ["reload"],

    mounted() {
        this.initData()
        this.isLogin = sessionStorage.getItem('4drt3d')
    },

    data() {
        return {
            isLogin: 0,
            logoPath: require('../../assets/img/logos/logo.png'),
            logoAlt: 'logo',
            cloudList: [
                {
                    id: '4',
                    name: '低代码平台',
                    component: 'ProductLowCode'
                },
                {
                    id: '5',
                    name: '大数据平台',
                    component: 'ProductBigData'
                },
            ],

            sideList: [
                {
                    id: '8',
                    name: '进销存',
                    component: 'ProductErp'
                },
                {
                    id: '9',
                    name: '小工单生产管理',
                    component: 'ProductTask'
                },
                {
                    id: '10',
                    name: 'WMS',
                    component: 'ProductWms'
                },
                {
                    id: '11',
                    name: 'MES',
                    component: 'ProductMes'
                },
                {
                    id: '12',
                    name: '工业物联网',
                    component: 'ProductIot'
                },
                {
                    id: '13',
                    name: 'OA办公',
                    component: 'ProductOa'
                },
            ],

            serviceSList: [
                {
                    id: '41',
                    name: 'x机加进销存和生产管理',
                    component: 'CaseErpTask'
                },
                {
                    id: '43',
                    name: 'x制药企业库存管理',
                    component: 'wmsCase'
                },
                {
                    id: '44',
                    name: 'x集团日常办公管理',
                    component: 'oaCase'
                },
                {
                    id: '42',
                    name: 'x光纤制造OTDR测试系统',
                    component: 'otdrCase'
                },
            ],

            hardwareList: [
                {
                    id: '41',
                    name: '工业平板',
                    component: 'hmi'
                },
                {
                    id: '42',
                    name: '智能采集网关',
                    component: 'Gateway'
                },
            ],

            suloteList: [
                {
                    id: '15',
                    name: '解决方案1',
                    img: require('../../assets/img/logos/logo1.png'),
                    component: 'PLC'
                }
            ],
        }
    },

    methods: {
        // 初始化整理数据
        initData() {
        },

        //菜单点击事件
        clickMenu(item) {
            let clickMenuName = item.component
            let currentMenu = this.$store.state.pubInfo.currentMenu
            if (clickMenuName !== currentMenu) {
                this.$store.commit('setCurrentMenu', clickMenuName)
                this.reload()
                this.$router.push({name: clickMenuName})
            }
        },

        // 主菜单点击事件
        otherClickMenu(type) {
            let pageName
            switch (type) {
                case 1: //首页
                    pageName = 'Main'
                    break

                case 2: //登录
                    pageName = 'Immediately'
                    break

                case 3: //关于我们
                    pageName = 'Aboutus'
                    break

                case 4: //登录
                    pageName = 'Press'
                    break
            }

            this.$store.commit('setCurrentMenu', pageName)
            this.reload()
            this.$router.push({name: pageName})
        },
    }
}
</script>

<style lang="less" scoped>
.header_container {
    margin: 0;
    height: 80PX;
    width: 100%;
    background: #02b2b5;
}

.header_left,
.header_center,
.header_right {
    float: left;
    height: 100%;
}

.header_left {
    padding: 20px 0px 8px 20px;
    cursor: pointer;
    // width: 10%;
    width: 200px;
    height: 100%;

    .logoImg {
        transform: scale(1.3);
    }
}

.header_center {
    position: relative;

    .sys_li_1 {
        // list-style: none;
        float: right;
        text-align: center;
        line-height: 80PX;
        cursor: pointer;
    }

    ul > li > a {
        font-size: 16PX;
        color: #fff;
        text-decoration: none;
        text-align: left;

    }

    a{
        font-size: 16PX !important;
    }

    dt {
        height: 55PX;
    }

    dd {
        text-align: left;
        width: 200px;
        line-height: 10PX !important;
    }


    .sys_title {
        padding: 0 30PX;
        font-size: 16PX;
        font-weight: 400;
        font-stretch: normal;
    }

    .newPart::after {
        content: '';
        right: 2px;
        position: absolute;
        width: 50px;
        height: 30px;
        background: url('../../assets/img/other/hot.png') no-repeat;
        background-size: cover;
    }

    .lookMore {
        font-size: 16px;
    }

    .lookMore:hover {
        color: #0a63a1;
        font-size: 16px;
    }
}

.header_right {
    padding: 0 20px;
    float: right;

    ul li {
        width: 80px;
        list-style: none;
        float: right;
        line-height: 40PX;
        margin-top: 20PX;
        border: 1px solid #02b2b5;
        border-radius: 20PX;

        a {
            color: #fff;
            text-decoration: none;
            font-size: 16PX;
            display: flex;
            align-content: center;
            justify-content: center;
            //letter-spacing: 2px;
        }
    }

    .login {
        background: #4879FE;
    }

    .dedicatedSystem {
        a {
            color: #0b0c0c;
        }

        border: 1px solid #4879FE;
    }
}

.el-icon-s-home {
    font-size: 38px;
}

.sys_ul {
    position: absolute;
    display: none;
    left: -60%;
    z-index: 1112;
    background: rgba(0, 0, 0, 0.9);
    width: 800px;
    // width: 40rem;
    //   padding: 0 0 30px 0;
}

.sys_ul_li {
    display: inline-block;
    width: 100%;
    margin: 0 20px;
}

.sys_ul_li dt {
    font-size: 16px;
    color: #2db5a3;
    font-weight: bold;
    text-align: left;
}

.sys_ul_li dd {
    width: 32%;
    float: left;
    line-height: 15px;
}

.sys_ul_li dd a {
    display: block;
    font-size: 16px;
    padding: 10px;
    margin: 5px 0;
    color: #fff;
    border-left: 1px solid #2db5a3;
    text-decoration: none;

    i {
        margin-right: 5px;
    }
}

.sys_ul_li dd a:hover {
    background: #333;
}

.sys_li_1:hover {
    background: rgba(0, 0, 0, 0.9);
    //   border-top: 4px solid #2db5a3;
}

.sys_li_1:hover .sys_ul {
    display: block !important;
}

.sys_ul_li2 {
    width: 20%;
    height: 16rem;
    float: left;
}

.sys_ul_li2 a {
    height: 16rem;
    display: block;
    margin: 10px;
    overflow: hidden;
    text-align: center;
    border: 1px solid #222;
    border-bottom: 1px solid #2db5a3;
}

.sys_ul_li2 a.bg1 {
    background: transparent;
}

.sys_ul_li2 a img {
    width: 100px;
    height: 100px;
}

.sys_ul_li2 a h3 {
    margin: 0;
    font-size: 16px;
    //letter-spacing: 2px;
    font-weight: 100;
    line-height: 20px;
    height: 3rem;
}

.sys_ul_li2 a p {
    font-size: 12px;
    margin-bottom: 1rem;
}

.sys_ul_li2 a:hover {
    background-color: #2db5a3;
    border-color: #2db5a3;
}

.sys_ul_port dd {
    width: 20%;
}

.sys_ul_other {
    left: -240%;
}

.sys_ul_li4 {
    clear: both;
    width: 100%;
}

.sys_ul_li4 ul {
    padding: 30px 0;
    overflow: hidden;
}

.sys_ul_li4 ul li {
    width: 32%;
    float: left;
    text-align: center;
    color: #fff;
}

.sys_ul_li4 ul li i {
    font-size: 44px;
    font-weight: 100;
}

.sys_ul_li4 ul li h4 {
    font-size: 14px;
    font-weight: 100;
    padding: 20px 0;
}

.sys_ul_li4 ul li p {
    font-size: 16px;
    font-weight: 500;
    line-height: 150%;
    color: orange;
}

.sys_ul_other > li > a {
    display: block;
    width: 18%;
    padding: 10px 0;
    margin: 0 1%;
    line-height: 28px;
    text-align: center;
    float: left;
    background: #333;
    font-size: 14PX;
}

.sys_ul_other li a:hover {
    background-color: #2db5a3;
    color: #000;
}

// 服务支持 20220920
.sys_ul_serviceSupport {
    width: 300px;
    left: -60%;
    padding: 20px auto;

    .sys_ul_li {
        margin: 0 auto;

        a {
            display: block;
            line-height: 50px;

        }

        :hover {
            color: #2db5a3;
        }
    }
}

// 产品 20220921
.sys_ul_pro {
    width: 800px;
    padding-bottom: 20px;

    .sys_pro_li dd {
        width: 32%;
    }

    .sys_pro_li dd a {
        display: block;
        font-size: 16px;
        padding: 10px;
        margin: 5px 0;
        color: #fff;
        text-decoration: none;
    }

    .first-a:hover {
        color: #fff !important;
        background: none;
    }

    .first-a {
    }

    .second-a {
        line-height: 20px !important;
        border: none !important;
    }

    :hover {
        color: #2db5a3 !important;
    }
}

// @media only screen and (max-width: 1068px) {
@media only screen and (max-width: 768px) and (min-width: 481px) {
    .header_container {
    margin: 0;
    height: 15rem;
    width: 100%;
    background: #02b2b5;
}

.header_left,
.header_center,
.header_right {
    float: left;
    height: 100%;
}

.header_left {
    padding: 20px 0px 8px 20px;
    cursor: pointer;
    // width: 10%;
    width: 200px;
    height: 100%;

    .logoImg {
        transform: scale(1.3);
        // width: 18px;
        // height: 54px;
        // margin-top: 3PX;
    }
}

.header_center {
    position: relative;

    .sys_li_1 {
        // list-style: none;
        float: right;
        text-align: center;
        line-height: 80px;
        cursor: pointer;
    }

    ul > li > a {
        font-size: 16px;
        color: #fff;
        text-decoration: none;
        text-align: left;

    }

    dt {
        height: 55px;
    }

    dd {
        text-align: left;
        width: 210px;
        line-height: 10px !important;
    }

    .sys_title {
        padding: 0 30px;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
    }

    .newPart::after {
        content: '';
        right: 2px;
        position: absolute;
        width: 50px;
        height: 30px;
        background: url('../../assets/img/other/hot.png') no-repeat;
        background-size: cover;
    }

    .lookMore {
        font-size: 16px;
    }

    .lookMore:hover {
        color: #0a63a1;
        font-size: 16px;
    }
}

.header_right {
    padding: 0 20px;
    float: right;

    ul li {
        width: 80px;
        list-style: none;
        float: right;
        line-height: 40px;
        margin-top: 20px;
        border: 1px solid #02b2b5;
        border-radius: 20px;

        a {
            color: #fff;
            text-decoration: none;
            font-size: 16px;
            display: flex;
            align-content: center;
            justify-content: center;
            //letter-spacing: 2px;
        }
    }

    .login {
        background: #4879FE;
    }

    .dedicatedSystem {
        a {
            color: #0b0c0c;
        }

        border: 1px solid #4879FE;
    }
}
    .header_container {
        .header_left {
            width: 35rem;
            height: 100%;

            .logoImg {
                // width: 13.5rem;
                height: 4.875rem;
                margin: 3rem 1rem;
            }
        }

        .header_center {
            ul {
                .sys_li_1 {
                    line-height: 14rem;
                    margin: 0.75rem auto;

                    .sys_title {
                        font-size: 4rem;
                        padding: 0 1.5rem;
                    }

                    .newPart::after {
                        background: none;
                    }

                    // 头部产品
                    .sys_ul_pro {
                        width: 108.5rem !important;
                        left: -162%;
                        padding: 0 0 2.875rem 0;

                        .sys_pro_li {
                            dt {
                                font-size: 3.5rem;
                            }

                            dd {
                                a {
                                    font-size: 3rem;
                                    padding: 0.625rem;
                                    margin: 3.3125rem 0;
                                    line-height: 2rem;
                                    height: 3rem;
                                    text-indent: 0rem;
                                }

                                li {
                                    .second-a {
                                        height: 5rem !important;
                                        line-height: 4.25rem !important;
                                    }
                                }
                            }
                        }

                        .sys_ul_li {
                            dt {
                                font-size: 3.5rem;
                            }

                            dd {
                                line-height: 2.9375rem;

                                a {
                                    font-size: 2.875rem;
                                    line-height: 4rem;
                                    padding: 2rem;
                                }
                            }
                        }
                    }

                    // 头部解决方案
                    .sys_ul_solution {
                        width: 110rem !important;
                        left: -265% !important;

                        .sys_ul_li2 {
                            height: 50rem;

                            a {
                                height: 50rem;

                                img {
                                    width: 11.25rem;
                                    height: 11.25rem;
                                }

                                h3 {
                                    font-size: 3rem;
                                    line-height: 6.25rem;
                                    height: 19rem;
                                }

                                p {
                                    font-size: 2.75rem;
                                }
                            }
                        }
                    }

                    // 头部关于我们
                    .sys_ul_other {
                        width: 99rem;
                        left: -353%;

                        .sys_ul_li4 {
                            ul {
                                li {
                                    i {
                                        font-size: 5.75rem;
                                    }

                                    h4 {
                                        font-size: 3rem;
                                        font-weight: bold;
                                        padding: 0.25rem 0;
                                        //letter-spacing: 1rem;
                                    }

                                    p {
                                        font-size: 3rem;
                                    }
                                }
                            }
                        }

                        li {
                            a {
                                width: 24%;
                                line-height: 6.75rem;
                                font-size: 3.5rem;
                            }
                        }
                    }

                    // 服务与支持
                    .sys_ul_serviceSupport {
                        width: 57rem;
                        left: -97%;

                        .sys_ul_li {
                            a {
                                font-size: 3rem;
                                height: 5rem;
                                line-height: 5rem;
                                //letter-spacing: 0.25rem;
                            }
                        }
                    }
                }
            }
        }

        .header_right {
            display: none;
            overflow: hidden;

            ul {
                li {
                    width: 16rem;
                    line-height: 7.5rem;
                    margin-top: 1.25rem;

                    a {
                        font-size: 2.875rem;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 480px) {
        .header_container {
    margin: 0;
    height: 80px;
    width: 100%;
    background: #02b2b5;
}

.header_left,
.header_center,
.header_right {
    float: left;
    height: 100%;
}

.header_left {
    padding: 20px 0px 8px 20px;
    cursor: pointer;
    // width: 10%;
    width: 200px;
    height: 100%;

    .logoImg {
        transform: scale(1.3);
        // width: 18px;
        // height: 54px;
        // margin-top: 3PX;
    }
}

.header_center {
    position: relative;

    .sys_li_1 {
        // list-style: none;
        float: right;
        text-align: center;
        line-height: 80px;
        cursor: pointer;
    }

    a {
        font-size: 3rem !important;
        // color: #fff;
        // text-decoration: none;
        // text-align: left;

    }

    dt {
        height: 35PX;
    }

    dd {
        text-align: left;
        width: 570px;
        line-height: 10PX !important;
    }

    .sys_title {
        padding: 0 30px;
        font-size: 16px;
        font-weight: 400;
        font-stretch: normal;
    }

    .newPart::after {
        content: '';
        right: 2px;
        position: absolute;
        width: 50px;
        height: 30px;
        background: url('../../assets/img/other/hot.png') no-repeat;
        background-size: cover;
    }

    .lookMore {
        font-size: 16px;
    }

    .lookMore:hover {
        color: #0a63a1;
        font-size: 16px;
    }
}

.header_right {
    padding: 0 20px;
    float: right;

    ul li {
        width: 80px;
        list-style: none;
        float: right;
        line-height: 40px;
        margin-top: 20px;
        border: 1px solid #02b2b5;
        border-radius: 20px;

        a {
            color: #fff;
            text-decoration: none;
            font-size: 16px;
            display: flex;
            align-content: center;
            justify-content: center;
            //letter-spacing: 2px;
        }
    }

    .login {
        background: #4879FE;
    }

    .dedicatedSystem {
        a {
            color: #0b0c0c;
        }

        border: 1px solid #4879FE;
    }
}
    .header_container {
        .header_left {
            width: 35rem;
            height: 100%;

            .logoImg {
                // width: 13.5rem;
                height: 4.875rem;
                margin: 3rem 1rem;
            }
        }

        .header_center {
            ul {
                .sys_li_1 {
                    line-height: 14rem;
                    margin: 0 auto;

                    .sys_title {
                        font-size: 4rem;
                        padding: 0 1.5rem;
                    }

                    .newPart::after {
                        background: none;
                    }

                    // 头部产品
                    .sys_ul_pro {
                        width: 108.5rem !important;
                        left: -162%;
                        padding: 0 0 2.875rem 0;

                        .sys_pro_li {
                            dt {
                                font-size: 3.5rem;
                            }

                            dd {
                                a {
                                    font-size: 3rem;
                                    padding: 0.625rem;
                                    margin: 3.3125rem 0;
                                    line-height: 2rem;
                                    height: 3rem;
                                    text-indent: 0rem;
                                }

                                li {
                                    .second-a {
                                        height: 5rem !important;
                                        line-height: 4.25rem !important;
                                    }
                                }
                            }
                        }

                        .sys_ul_li {
                            dt {
                                font-size: 3.5rem;
                            }

                            dd {
                                line-height: 2.9375rem;

                                a {
                                    font-size: 2.875rem;
                                    line-height: 4rem;
                                    padding: 2rem;
                                }
                            }
                        }
                    }

                    // 头部解决方案
                    .sys_ul_solution {
                        width: 110rem !important;
                        left: -265% !important;

                        .sys_ul_li2 {
                            height: 50rem;

                            a {
                                height: 50rem;

                                img {
                                    width: 11.25rem;
                                    height: 11.25rem;
                                }

                                h3 {
                                    font-size: 3rem;
                                    line-height: 6.25rem;
                                    height: 19rem;
                                }

                                p {
                                    font-size: 2.75rem;
                                }
                            }
                        }
                    }

                    // 头部关于我们
                    .sys_ul_other {
                        width: 99rem;
                        left: -353%;

                        .sys_ul_li4 {
                            ul {
                                li {
                                    i {
                                        font-size: 5.75rem;
                                    }

                                    h4 {
                                        font-size: 3rem;
                                        font-weight: bold;
                                        padding: 0.25rem 0;
                                        //letter-spacing: 1rem;
                                    }

                                    p {
                                        font-size: 3rem;
                                    }
                                }
                            }
                        }

                        li {
                            a {
                                width: 24%;
                                line-height: 6.75rem;
                                font-size: 3.5rem;
                            }
                        }
                    }

                    // 服务与支持
                    .sys_ul_serviceSupport {
                        width: 57rem;
                        left: -97%;

                        .sys_ul_li {
                            a {
                                font-size: 3rem;
                                height: 5rem;
                                line-height: 5rem;
                                //letter-spacing: 0.25rem;
                            }
                        }
                    }
                }
            }
        }

        .header_right {
            display: none;
            overflow: hidden;

            ul {
                li {
                    width: 16rem;
                    line-height: 7.5rem;
                    margin-top: 1.25rem;

                    a {
                        font-size: 2.875rem;
                    }
                }
            }
        }

        .sys_ul_li_dd {
            width: 55rem !important;
            a{
                margin-left: 55px !important;
                margin-top: 25px !important;
            }
        }
    }
}
</style>
