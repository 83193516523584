<template>
    <el-container style="height: 100%">
        <el-header>
            <common-header></common-header>
        </el-header>
        <el-main>
            <router-view></router-view>
        </el-main>
    </el-container>
</template>

<script>
import commonHeader from '../components/header/header.vue'
import demand from '@/api/data.js'

export default {
    name: 'Home',
    components: {
        commonHeader
    },
    mounted() {
        this.initGetAppid()
    },
    methods: {
        // 获取调用微信接口参数
        initGetAppid() {
            let tempData = {
                parType: 'scanLogin'
            }
            demand.home.getWechatParamer(tempData).then((res) => {
                if (res.code == 1) {
                    this.$store.commit('setAppID', res.object.appId)
                    this.$store.commit('setSecret', res.object.secret)
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.el-header {
    width: 100%;
    height: 80px !important;
    position: fixed;
    z-index: 9999999;
    padding: 0;
}

.el-main {
    padding: 0;
    overflow-x: hidden;
}
</style>
